footer{
    background-color: #F8FAFC;
}
.footer-content{
    text-align: justify;
    font-size: 13.66px;
    font-weight: 400;
}
.footer-headings{
    font-size: 11.1px;
    font-weight: 600;
    color: #01A8CC;
}
.footer-links{
    text-decoration: none;
    color: black;
    font-size: 13.66px;
    font-weight: 500;
}
.link-text{
    font-size: 11.95px;
    font-weight: 700;
}
.copyrighttext{
    font-size: 11.95px;
    font-weight: 400;
}
.footer-description{
    font-size: 13.66px;
}


@media only screen and (min-width: 601px) {
    .main {
      margin-left: 15%;
    }
  }
  @media only screen and (max-width: 600px) {
    .childmain {
      margin-left: 5%;
    }
  }