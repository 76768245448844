.navbar-links {
  color: black;
  text-decoration: none;
}
.flex-container {
  display: flex !important;
  align-items: center !important; /* Align items vertically */
  justify-content: flex-end !important; /* Align items to the end of the container */
  flex-direction: row !important;
  gap: 5px;
}

.flex-container li a {
  text-decoration: none;
  color: black;
}

.likeBtn {
  border: 1px solid #01a8cc !important;
  border-radius: 25px !important;
  background-color: #01a8cc !important;
  color: white !important;
  width: 80.03px;
}

.search-container {
  position: relative;
  width: 200px;
  margin: auto;
}

.input-group {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%;
  border-radius: 30px;
  outline: none;
  padding-left: 30px;
  padding-right: 40px; /* Add padding to make room for the icon */
}

.search-icon-container {
  /* position: absolute; */
  /* right: 10px; */
  display: flex;
  align-items: center;
  pointer-events: none;
  /* left: 0; */
  top: 0;
  z-index: 5;
  font-size: 13px;
}

.search-icon {
  color: #aaa;
}

.search-results {
  position: absolute;
  top: calc(100% + 5px);
  width: 100%;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 4px;
}

.list-group-item {
  cursor: pointer;
}


.user-info-card {
  font-family: Arial, sans-serif;
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Styling for each info item */
.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

/* Styling for the label elements */
.lable {
  font-weight: bold;
  color: #333;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  min-width: 120px;
}

/* Styling for the icons */
.lable i {
  margin-right: 8px;
  color: #4caf50;
}

/* Styling for the span elements */
.span {
  margin-left: 10px;
  color: #555;
  flex-grow: 1;
  text-align: left;
}

/* Add a hover effect to the info items */
.info-item:hover {
  background-color: #f9f9f9;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

/* Adjust margin and padding for mobile responsiveness */
@media (max-width: 600px) {
  .user-info-card {
    margin: 10px;
    padding: 15px;
  }

  .info-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .label {
    margin-bottom: 5px;
  }

  .span {
    margin-left: 0;
  }
}