.border-none {
    border: none !important;
}

.gradiant-text {
    background: linear-gradient(to right, #16C062, #00BCE0);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.Btn-bg {
    background-color: #01A8CC !important;
    color: white !important;
    border: none !important;
}

.linearBg {
    background: linear-gradient(to right, #16C062, #00BCE0) !important;
    border: none !important;
}

.linearBorder1 {
    /* border-color: linear-gradient(top, #16C062, #00BCE0) !important; */
    border-image: linear-gradient(#16C062, #00BCE0) 1 !important;
    border-width: 4px !important;
    border-style: solid !important;
    border-radius: 10px !important;
}

  .linearBorder {
    border-width: 2px !important;
    border-style: solid !important;
    border-image: linear-gradient(to right, #16C062, #00BCE0) 1 !important; /* Apply gradient as border image */
    border-radius: 3px !important;
  }
.backBtn{
    background-color: white;
    color: #01A8CC;
    width: fit-content;
    padding: 5px;
    margin-left: 10px;
    margin-top: 50px;
    position: absolute;
    border-radius: 25px;
    padding-left: 10px;
    padding-right: 10px;
}
.fontWeight700{
    font-weight: 700;
}
.fontWeight500{
    font-weight: 500;
}
.fontWeight400{
    font-weight: 400;
}
.primaryColor{
color: #01A8CC;
}
.secondaryColor{
    color: #7F56D9;
}
.gradiantHeadings{
    font-size: 34.82px;
    font-weight: 600;
  }
.subText{
    font-size: 19.34px;
    font-weight: 400;
}
.linearAvatrBorder {
    border-width: 4px !important;
    border-style: solid !important;
    border-image: linear-gradient(to right, #16C062, #00BCE0) 1 !important; /* Apply gradient as border image */
    border-radius: 25px !important;
  }
.cancelBtn{
border: 1px solid #01A8CC !important;
border-radius: 25px !important;
background-color: white !important;
color: #00BCE0 !important;

}
.SubmitBtn{
    border: 1px solid #01A8CC !important;
    border-radius: 25px !important;
    background-color: #01A8CC !important;
    color: white !important;
}