.left-sidebar {
    background-color: #ffffff;
    padding: 20px;
    border-right: 1px solid #dee2e6;
    /* overflow-y: auto !important; */
    /* height: 80vh; */
}

.rightSidebar {
    padding: 0px !important;
}

.chatMessages {
    /* height: calc(70vh - 150px); 
    overflow-y: auto; */
}

.chatMessage {
    margin-bottom: 10px;
}

.likeBtn{
    border: 1px solid #01A8CC !important;
    border-radius: 25px !important;
    background-color: #01A8CC !important;
    color: white !important;
    width: 80.03px;
  }


  .inputcontainer {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    width: 90%;
  }
  
  .searchicon {
    position: absolute;
    left: 10px;
    color: #aaa;
  }
  
  .searchinput {
    border: none;
    outline: none;
    padding-left: 30px;  /* to make space for the icon */
    width: 100%;
  }
  
  .searchinput:focus {
    outline: none;
    border-color:#01A8CC;
  }
  
  .searchinput::placeholder {
    color: #aaa;
  }
  
  .inputcontainer:focus-within {
    border-color: #01A8CC;
  }