.Btns{
    width: 176.62px !important;
    font-size: 18.29px !important;
    font-weight: 400 !important;
}
.logoutHeading{
    font-size: 24px !important;
    font-weight: 700 !important;
}


#modalstyle{
    background-color: red;
    width: 10px;
}

