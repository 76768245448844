.confirm-button-class {
    background-color: red !important;
    color: white !important;
    border: none !important;
  }
  
  .title-class {
    font-size: 24px !important;
    font-weight: 700 !important;
  }
  
  
  .icon-class {
    font-size: 10px !important;
  }
  
  .confirm-button-class .swal2-icon svg {
    width: 12px !important;
    height: 12px !important;
  }
  
  .swal2-actions .swal2-confirm {
    background-color: #01A8CC !important;
    color: white !important;
    border: none !important;
    box-shadow: none !important;
    width: 170px;
    border-radius: 18.63px;
  }
  
  .swal2-actions .swal2-cancel {
    color: #141105 !important;
    background-color: #f1f0ec !important;
    box-shadow: none !important;
    border: 1px solid #01A8CC !important;
    width: 170px;
    border-radius: 18.63px;
  }
  
  .swal2-confirm:focus, .swal2-cancel:focus {
    /* box-shadow: none !important; */
    /* border: none !important; */
  }
  
  .swal2-actions button:hover {
    /* border: none !important; */
  }