.nav-underline {
    --bs-nav-underline-gap: 0rem;
}
.nav{
  --bs-nav-link-padding-x: 0rem;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-bottom: 1px solid gray !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    color: black;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active:hover {
    border-bottom: 1px solid gray !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    border-bottom: 2px solid gray !important;
    font-weight: 700;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.nav-tabs {
    display: flex;
    /* justify-content: space-between; */
    /* gap: 10px; */
    border-bottom:none !important;
}

/* styles.css */
.nav-link {
    border: none;
    cursor: pointer;
    position: relative;
    font-weight: 700;
  }
  
  .nav-link::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: transparent; /* Initially transparent */
    transition: background-color 0.3s ease-in-out;
  }
  
  .nav-link.active::after {
    background-color: #01A8CC; /* Active tab bottom line color */
    height: 2px;
    
  }
  
  .nav-link{
    width: 200%;
    color: black !important;
  }
  .nav-link.nav-link.active {
    color: #01A8CC !important;
  }

  .loginSignup{
    padding: 15px;
  }
  
  .color-overlay {
    min-height: 525px;
    background: rgb(0, 0, 0);
    /* Fallback color */
    background: rgba(0, 0, 0, 0.5);
    /* Black background with 0.5 opacity */
    color: #f1f1f1;
    /* background-color: rgba(0, 0, 0, .1); */
}

@media (max-width:767.98px) {
    .color-overlay {
        min-height: 400px;
    }
}

@media (max-width:1367px) {
  .nav-link {
    width: 160px;
    color: black !important;
  }
}

@media (max-width:1200px) {
  .nav-link {
    width: 100px;
    color: black !important;
  }
}


@media (max-width:768px) {
  .nav-link {
    width: 100%;
    color: black !important;
  }
}

@media (max-width:767px) {
  .nav-link {
    width: 100%;
    color: black !important;
  }
}


@media (max-width:576px) {
  .nav-link {
    width: 225px;
    color: black !important;
  }
}


@media (max-width:520px) {
  .nav-link {
    width: 190px;
    color: black !important;
  }
}

@media (max-width:449px) {
  .nav-link {
    width: 150px;
    color: black !important;
  }
}
@media (max-width:370px) {
  .nav-link {
    width: 100px;
    color: black !important;
  }
}
.form-style{
  border: 2px solid #cbcbcb;
}
.backtext{
  font-size: 14px;
}

.rounded-gradient-borders {
  width: 80px;
  height: 80px;
  border: double 2px transparent;
  border-radius: 50%;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #16C062,#00BCE0);
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: inline-block;
  /* box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); */
}

.rounded-corners-gradient-borders-2 {
  border: double 10px black;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-clip: content-box;
  background-color: red;
  float: left;
}


.linearBorder {
  border: 0;
}

.toggle-password{
  cursor: pointer;
  background: transparent;
  border: 0 !important;
  border-right: 0 ;
  display: flex;
  align-items: center;
}

.input-group .linearBorder + .toggle-password {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}