.sidelinks {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none !important;
  color: black !important;
  font-size: 15.53px !important;
  font-weight: 400 !important;
}

.sidebaactivetabicon {
  color: #01A8CC;
  /* width: 50px; */
}

.sidebadeactivetabicon {
  color: #0d0d0e;
  /* width: 50px; */
}

.sidebaactivetabname {
  color: #01A8CC;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
  width: 100px;
}

.sidebadeactivetabname {
  color: #0e0e0e;
  font-weight: bold;
  text-align: left;
  margin-left: 10px;
  width: 100px;
}

.toggleButton {
  cursor: pointer;
}

@media (max-width: 768px) {
  .sidelinks {
    padding: 8px 10px;
  }
}

.swal2-cancel {
  background-color: #dededf !important;
  color: #fff !important;
  border: 1px solid blue !important;
}

.swal2-confirm {
  background-color: #dc3545 !important;
  color: #fff !important;
  border: none !important;
}
