/* .avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  } */

  .avatar {
    width: 100px; 
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
  }
  
  .front {
    z-index: 3; /* Make front image appear on top */
  }
  
  .right {
    transform: translateX(25px); /* Adjust to position the right image */
  }
  
  .left {
    transform: translateX(-25px); /* Adjust to position the left image */
  }
  
  .back {
    opacity: 0.5; /* Make back image slightly transparent */
  }
  
  .imageStack {
    position: relative;
    width: 100px; /* Adjust according to your preference */
    height: 70px; /* Adjust according to your preference */
    padding-right: 50px !important;
  }

  .listHeadings{
    font-size: 17.41px;
}
.questiontext{
  font-size: 19.34px;
}
.questiondesc{
  font-size: 17.41px;
}
.questionBtn{
  font-size: 15.48px;
}
.getInTouch{
  background-color: #F9FAFB !important;
  border: none !important;
}
