.homepage-container {
    /* position: relative;
    text-align: center;
    color: white;
    height: 725px; */
    /* background: linear-gradient(90deg, #16C062 29%, #00BCE0 29%);  */
}

.centered {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.border-configuration {
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
}

.border-configuration:nth-child(1) {
    border-bottom: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
}

.border-configuration:nth-child(2) {
    border-bottom: 1px solid #ced4da;
}

.border-configuration:nth-child(3) {
    border-bottom: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
}

.border-configuration:nth-child(4) {
    border-right: 1px solid #ced4da;
    border-top: 1px solid #ced4da;
}

.border-configuration:nth-child(5) {
    border-top: 1px solid #ced4da;
}

.border-configuration:nth-child(6) {
    border-left: 1px solid #ced4da;
    border-top: 1px solid #ced4da;
}

.bannerHeading {
    font-size: 36.06px;
    font-weight: 400;
}

.bannerPragraph {
    font-size: 21.63px;
    font-weight: 400;
    line-height: 26.18px;
}

.seprate-heading {
    color: #01A8CC;
}

header {
    background: no-repeat center center;
    background-size: cover;
    color: white;
}

.color-overlay {
    min-height: 725px;
    background: rgb(0, 0, 0);
    /* Fallback color */
    background: rgba(0, 0, 0, 0.5);
    /* Black background with 0.5 opacity */
    color: #f1f1f1;
    /* background-color: rgba(0, 0, 0, .1); */
}

@media (max-width:767.98px) {
    .color-overlay {
        min-height: 30vh;
    }
}

.noborder {
    border: none !important
}

/* .color-overlay .content{
    background: rgb(0, 0, 0); 
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
} */

.home-text {
    background: linear-gradient(to right, #16C062 , #00BCE0);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 23.9px;
    font-weight: 500;
}

.home-text2{
    font-size: 17.93px;
    font-weight: 400;
}
.skill-listBtn {
    border-radius: 25px !important;
    font-size: 21.35px !important;
}
.link-style{
    color: white;
    text-decoration: none;
}
.aboutSkills{
    font-size: 35.93px;
    font-weight: 700px;
}
.cardHeading{
    font-size: 17.96px;
    font-weight: 700;
}
.cardDescription{
    font-size: 13.96px;
    padding: 40px;
    text-align: justify;
}