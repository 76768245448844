.left-sidebar {
    background-color: #ffffff;
    padding: 20px;
    border-right: 1px solid #dee2e6;
    /* overflow-y: auto !important; */
    /* height: 80vh; */
}

.rightSidebar {
    padding: 0px !important;
}

/* .chatMessages {
    width: 682px;
    height: 607px;
} */

.chatMessage {
    margin-bottom: 10px;
}

.likeBtn{
    border: 1px solid #01A8CC !important;
    border-radius: 25px !important;
    background-color: #01A8CC !important;
    color: white !important;
    width: 80.03px;
  }

  .unlikeBtn{
    border: 1px solid #eeeff0 !important;
    border-radius: 25px !important;
    background-color: #edeeee !important;
    color: rgb(24, 23, 23) !important;
    width: 80.03px;
  }
  /* .cardui{
    width: 100%;
    height: 50%;

  } */

 
  