.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

.sidebar a.active {
  background-color: #04AA6D;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

.content {
  margin-left: 10px;
  padding: 1px 16px;
  height: 1000px;
}

.profilebg {
  background-color: #e2f6f2 !important;
  height: 113px !important;
  border: none !important;
  border-radius: 30px !important;
}

.avatarcontainer {
  position: relative; /* Make the container relative */
}

.avatar {
  border-radius: 150%;
  position: absolute;
  margin-top: 20%;
  margin-left: 20%;
  height: 100%;
  width: 100%;
  object-fit: cover;
 
}

/* Media query for mobile */
@media only screen and (max-width: 600px) {
  .avatar {
    width: 100px; /* Adjust width for mobile */
    height: 100px; /* Maintain aspect ratio */
    margin-top: 10%; /* Adjust margin top for mobile */
    margin-left: 10%; /* Adjust margin left for mobile */
  }
}

/* Media query for larger screens (web) */
@media only screen and (min-width: 601px) {
  .avatar {
    width: 160px; /* Original width for web */
    height: 150px; /* Original height for web */
  }
}

.editicon {
  position: absolute;
  top: 100%; /* Position relative to the top of the parent container */
  left: 50%; /* Position relative to the right of the parent container */
  transform: translate(50%, -50%); /* Center the icon */
  cursor: pointer;
  background-color: rgb(250, 250, 250);
  border-radius: 50%;
  padding: 4%;
  z-index: 999;
}


.front {
  z-index: 3;
  /* Make front image appear on top */
  position: relative;
  top: 10px
}
.iconnew{
  font-size: 170%;
  color: #818181;
}

.imageStack {
  position: relative;
  width: 100px;
  /* Adjust according to your preference */
  height: 70px;
  /* Adjust according to your preference */
  padding-right: 50px !important;
}

.aboutDesc {
  background-color: #F9FAFB !important;
  border: none !important;
}

.sidebarDiv {
  border-right: 1px solid lightgray !important;
  position: fixed;
  z-index: 1;
  background: white;
}

.yourSkills {
  background-color: #F6F6F6 !important;
}

/* @media (max-width:768px) { */
.contentsm {
  margin-left: 132px;
  padding: 1px 16px;
  height: 1000px;
}

/* } */

.profileHeadings {
  font-size: 24px;
  font-weight: 400;
  height: 80px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.sidelinks {
  text-decoration: none;
  color: black;
  font-size: 15.53px;
  font-weight: 700;
}

.newPostBtn {
  background-color: white !important;
  border-radius: 25px !important;
  border: 1px solid #01A8CC !important;
  font-size: 18.5px !important;
  font-weight: 400 !important;
  color: #01A8CC !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  height: 38.33px !important;
  width: 195px;
}

@media (max-width:864px) {
  .skillsbuild {
    margin-bottom: 100px !important;
  }
}

.postBtn {
  width: 217.17px !important;
  font-family: Inter !important;
  font-size: 22.49px !important;
  font-weight: 500 !important;
  line-height: 27.21px !important;
  text-align: center !important;
  border-radius: 25px !important;

}
.postInputLabel{
  font-size: 16.86px !important;
  font-weight: 400 !important;
}

.Btns{
  width: 176.62px !important;
  font-size: 18.29px !important;
  font-weight: 400 !important;
}
.formHeading{
  font-size: 16.68px !important;
  font-weight: 400 !important;
}

.text{
  text-align: justify;
}


.custommultiselect {
  position: relative;
}

.custommultiselect input {
  padding-right: 30px; /* Add padding to the right to make space for the icon */
}

.custommultiselect .dropdownicon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 12px;
  color: #000; /* Change the color as needed */
}
